import request from "@http";

// 现房房源列表
export function getReadyPageList(params) {
    return request({
        method: "get",
        url: `/api/gzf/ready/apartment/page`,
        params,
    });
}

// 行政区查询
export function getDistrictList(params) {
    return request({
        method: "get",
        url: `/wechat/ready/apply/district`,
        params
    });
}

// 区域查询
export function getAddressDistrictList(params) {
    return request({
        method: "get",
        url: `/wechat/ready/apply/addressDistrict`,
        params
    });
}

// 新增现房房源
export function readyApartmentSave(data) {
    return request({
        method: "post",
        url: `/api/gzf/ready/apartment/save`,
        data,
    });
}
// 修改现房房源
export function readyApartmentUpdate(data) {
    return request({
        method: "post",
        url: `/api/gzf/ready/apartment/update`,
        data,
    });
}

