export const tableColumn = [
    {
        prop: "district",
        label: "行政区",
        showOverflowTooltip: true
    },
    {
        prop: "addressDistrict",
        label: "区域地址",
        // width: 100
    },
    {
        prop: "address",
        label: "房源地址",
        // width: 100
    },
    {
        prop: "area",
        label: "面积m²",
        render: (h, {row: {area}}) => {
            const timeStamp = area ? area + "m²" : '';
            return h("span", timeStamp);
        }
    },
    {
        prop: "comment",
        label: "备注"
    },
];

