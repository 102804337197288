<template>
    <section class="existing-listings">
        <div class="top-bar bg-white">
            <el-select v-model="formSearch.district" @change="getAddressDistrictList" placeholder="请选择行政区进行筛选" size="small">
                <el-option v-for="(item,index) in districtList" :key="index" :label="item" :value="item"/>
            </el-select>
            <el-select v-model="formSearch.addressDistrict" @change="getDistrictList" placeholder="请选择区域进行筛选" size="small" style='margin-left: 10px'>

                <el-option v-for="(item,index) in addressDistrictList" :key="index" :label="item" :value="item"/>
            </el-select>
            <!--    搜索框-->
            <el-input v-model="formSearch.keyword" placeholder="请输入内容" style="margin: 0 10px; width: 250px"
                      size="small"/>
            <el-button type="primary" @click="handleChange" size="small">查询</el-button>
            <el-button size="small" @click="handleResetting">重置</el-button>

            <el-button type="primary" icon="el-icon-plus" size="small" @click="handleCancel('add')">新建房源</el-button>
            <el-button type="primary" icon="el-icon-upload" size="small" @click="handleImport">导入房源</el-button>
        </div>
        <r-e-table class="bg-white" ref="tableRef" :dataRequest="getReadyPageList" :height="820" :columns="tableColumn"
                   :query="formSearch">
            <el-table-column slot="toolbar" label="操作" width="200">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <span class="table-btn" @click="handleCancel('exit',row)">修改</span>
                    </div>
                </template>
            </el-table-column>
            <template slot="empty">
                <el-empty/>
            </template>
        </r-e-table>

        <layer-save-existing-listings ref="layerSaveExistingListings" />

        <layer-existing-listings-import ref="layerExistingListingsImport" />
    </section>
</template>

<script>
import { tableColumn } from "@/views/rental-housing-management/existing-listings/data";
import { getReadyPageList, getDistrictList, getAddressDistrictList } from '@/api/existing-listings';
export default {
    name: "existing-listings",
    data() {
        return {
            tableColumn,
            formSearch: {
                keyword: null,
                district: null,
                addressDistrict: null,
            },
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
            districtList: [],
            addressDistrictList: [],
        };
    },
    components: {
        layerSaveExistingListings: () => import("@/views/rental-housing-management/existing-listings/components/layer-save-existing-listings"),
        layerExistingListingsImport: () => import("@/views/rental-housing-management/existing-listings/components/layer-existing-listings-import")
    },
    created() {},
     mounted() {
       this.initList();
    },
    methods: {
        async initList(){
            const {list:districtList} = await getDistrictList();
        const {list:addressDistrictList} = await getAddressDistrictList();
        this.districtList = districtList;
        this.addressDistrictList = addressDistrictList;
        },
        getReadyPageList(params) {
            return getReadyPageList(params);
        },
      async getDistrictList(){
        const params = {
          addressDistrict:this.formSearch.addressDistrict
        }
        const {list:districtList} = await getDistrictList(params);
        this.districtList = districtList;
      },
      async getAddressDistrictList(){
        const params = {
          district:this.formSearch.district
        }

        const {list:addressDistrictList} = await getAddressDistrictList(params);
        this.addressDistrictList = addressDistrictList;
      },

        handleChange() {
            this.$refs["tableRef"].getTableData();
        },

        handleResetting(){
            // this.formSearch = { };
            this.formSearch.keyword = null;
            this.formSearch.district = null;
            this.formSearch.addressDistrict = null;
            this.initList();
            this.handleChange();
        },

        handleCancel(type, data = {}){
            this.$refs["layerSaveExistingListings"].openDialog(type,data);
        },

        handleImport(){
            this.$refs["layerExistingListingsImport"].openDialog();
        }
    },
}
</script>


<style lang="scss" scoped>
.existing-listings {
    padding: 0 VW(15px);

    .top-bar {
        display: flex;
        margin: VH(10px) 0 VH(15px);
        padding: VH(15px) VW(15px);
        //justify-content: center;
    }

    /deep/ .r-e-table {
        .el-table .cell {
            white-space: nowrap;
            text-align: center;
        }
    }
}
</style>
